const dateStringValidator = date => {
  return !isNaN(new Date(date).getDate())
}

const dateStringTommddyyyy = date => {
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }).replace(/\//g, '-')
}

export {
  dateStringValidator,
  dateStringTommddyyyy
}
