<template>
  <ul class="breadcrumbs">
    <li
      v-for="(item, index) in items"
      :key="`breadcrumb#${index}`"
      :class="{'prev-level': index === items.length - 1}"
      data-testid="breadcrumbsItem"
    >
      <router-link
        :to="`/category/articles/${item.slugId}`"
        data-testid="breadcrumbsLink"
      >
        {{ item.name }}
      </router-link>
    </li>

    <li>
      <span data-testid="breadcrumbsCurrentState">
        {{ articleTitle }}
      </span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  props: {
    items: {
      type: Array,
      required: true,
      validator: function (breadcrumbs) {
        const keys = ['name', 'slugId']
        return breadcrumbs.every(type => keys.every(key => key in type))
      }
    },
    articleTitle: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumbs {
  background-color: $white;
  margin: 0;
  padding: 0;
  display: flex;

  & > li + li::before {
    content: '/';
    padding: 0 8px;
    color: $grey-5;
    font-family: inherit;
    position: relative;
    top: 1px;
  }

  li {
    text-transform: initial;
    font-size: 1rem;
    line-height: 16px;
    list-style: none;
    margin: 0;

    a,
    span {
      display: inline-block;

      @extend %typography-b1;
    }

    a {
      font-weight: normal;
      position: relative;
      text-decoration: underline;

      @extend %link-styles;
    }
  }

  @media only screen and (max-width: $breakpoint-md-max) {
    li {
      display: none;

      &.prev-level {
        display: flex;
        align-items: center;

        &::before {
          content: '';
          width: 5px;
          height: 10px;
          background: url('../../assets/svg/chevron.svg') no-repeat;
          background-size: contain;
          background-position: center;
          padding: 0;
          display: block;
          top: auto;
          margin-right: 8px;
        }
      }
    }
  }
}
</style>
